<template>
    <div class="messageList page-container">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>消息管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="btnBox">
            <el-button size="small" type="primary" @click="$router.push('createMessage')" v-if="isAdmin">发布消息</el-button>
            <el-button size="small" type="danger" :disabled="selectedData.length === 0" @click="delMessage()">删除消息</el-button>
            <el-button size="small" @click="refresh()">刷新</el-button>
        </div>

        <el-tabs v-model="type" @tab-click="changeType">
            <el-tab-pane v-for="item in messageType" :label="item.type" :name="item.code" :key="item.code"></el-tab-pane>
        </el-tabs>
        <div class="tableBox">
            <el-table :data="tableData" :row-key="row => row.bid" @selection-change="handleSelectionChange"
                      v-loading="loading" ref="table">
                <el-table-column type="selection" :reserve-selection="true" width="45" align="center"></el-table-column>
                <el-table-column prop="no" label="序号" width="55" align="center">
                    <template slot-scope="scop">
                        {{ (pager.current - 1) * pager.size +  scop.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="资源池" show-overflow-tooltip >
                    <template slot-scope="scope">
                        {{ points.find(i => i.regionId === regionId)?.regionName  }}
                    </template>
                </el-table-column>
                <el-table-column prop="readFlg" label="已读/未读" width="200" align="center">
                    <template slot-scope="scope">
                        <span class="status"
                              @click="lookDetails(scope.row)">{{ scope.row.readFlag == "READ" ? "已读" : "未读" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="消息标题" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="title" @click="lookDetails(scope.row)">
                            【{{ messageType.find(i => i.code === scope.row.type)?.type }}】{{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createdAt" label="时间" align="center" width="200px">
                    <template slot-scope="scop">
                        {{ moment(scop.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200px">
                    <template slot-scope="scope"><el-button type="text" size="small" @click="lookDetails(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                   @currentChange="handleCurrentChange"  @sizeChange="handleSizeChange"></Pager>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {messageType} from "@/utils/constant";
import moment from "moment";
import storage from "@/utils/storage";
import {getUnreadCount} from "@/utils/message";


export default {
    name: 'messageManage',
    computed: {
        ...mapGetters(['isAdmin', 'points'])
    },
    data() {
        return {
            type: '',
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            tableData: [],
            selectedData: [],
            isRefresh: false,
            loading: false,
            messageType,
            regionId: ''
        }
    },
    created() {
        this.getList()
        this.regionId = storage.get('REGION_ID')
    },
    methods: {
        moment,
        getList() {
            let data = {pageNum: this.pager.current, pageSize: this.pager.size }
            if (this.type && this.type !== '0') {
                data.type = this.type
            }
            this.$postJson('/message/listPage', data)
                .then((res) => {
                    if (res.code === '200') {
                        this.pager.total = res.data.total || 0
                        this.tableData = res.data.records
                    }
                })
                .finally(() => {
                    this.isRefresh = false
                })
        },
        lookDetails(item) {

            this.$router.push('/messageDetail?bid=' + item.bid)
        },
        handleSelectionChange(val) {
            this.selectedData = val.map((item) => item.bid)
        },
        handleCurrentChange(val) {
            this.pager.current = val
            this.getList()
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.getList();
        },
        delMessage() {
            if (this.selectedData.length == 0) {
                this.$message.error('请至少选择一条消息')
                return
            }
            this.$confirm('删除后将无法找回这些消息，您确认删除这些消息吗？', '删除消息', {
                type: 'warning',
            }).then(() => {
                this.$postJson('/message/delete', {messageIdList: this.selectedData})
                .then((res) => {
                    this.$message.success('删除消息成功')
                    this.pager.current = 1
                    this.$refs.table.clearSelection()
                    this.getList()
                    getUnreadCount()
                })
            })
        },
        refresh() {
            this.isRefresh = true
            this.getList()
        },
        changeType() {
            this.pager.current = 1
            this.$refs.table.clearSelection()
            this.getList()
        },
    },
}
</script>
<style lang="scss" scoped>
.messageList {
    /deep/ {
        .el-tabs__header {
            margin-top: 24px;
            margin-bottom: 0;
            padding: 0 40px;
            background-color: #fff;
        }

        .el-tabs__nav-wrap::after {
            content: none;
        }
    }

    .tableBox {
        margin-top: 8px;

        .status {
            cursor: pointer;
        }

        .title {
            text-align: left;
            color: #006bff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
        }
    }
}
</style>
